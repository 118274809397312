import React from 'react';
import './ConnectAddress.css';

function ConnectAdress() {
  return (
    <section className="connect-address">
      {
        true ?
          (
            <aside>
              <span className="circle"></span>
              <p>connect metamask</p>
            </aside>
          )
          :
          (
            <aside>
              <span className="circle connected"></span>
              <p>0xfa---23ds</p>
            </aside>
          )
      }
    </section>
  )
}

export default ConnectAdress;