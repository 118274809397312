import React from 'react';
import Layout from '../components/reusable/Layout';
import './Home.css';

function Home() {
  return (
    <Layout>

      <section className="introduction">
        <h1><span>Quid</span><span>Raise</span></h1>
        <p>A decentralized fund-raising services that helps you contribute to projects on the blockchain network.</p>
      </section>

      <section className="get-started">
        <h2>Get Started</h2>
        <p>is a super easy 3 step process.</p>
        <ul>
          <li>
            <section>
              <h4>1.</h4>
              <p>Connect to wallet</p>
            </section>
          </li>
          <li>
            <section>
              <h4>2.</h4>
              <p>Create and share project</p>
            </section>
          </li>
          <li>
            <section>
              <h4>3.</h4>
              <p>Receive donations</p>
            </section>
          </li>
        </ul>
      </section>

    </Layout>
  )
}

export default Home;