import React from 'react';
import { ReactComponent as Logo } from './logo.svg';
import './Branding.css';

function Branding() {
  return (
    <section className="branding">
      <section>
        <Logo />
      </section>
    </section>
  )
}

export default Branding;