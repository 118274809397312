import React, { ReactNode } from 'react';
import Branding from './Branding';
import ConnectAdress from './ConnectAddress';
import TopNavigation from './Nav';
import './Layout.css';

type Props = {
  children: ReactNode
  pageTag?: ReactNode
}

function Layout(props: Props) {
  return (
    <section>

      <aside>
        <Branding />
      </aside>

      <header>
        <div></div>
        <TopNavigation />
        <ConnectAdress />
      </header>

      <main>
        <aside>
          <p>{props.pageTag}</p>
        </aside>
        <section>{props.children}</section>
      </main>
    </section>
  )
}

export default Layout;