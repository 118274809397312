import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Dashboard } from './dashboard.svg';
import { ReactComponent as Contributions } from './contributions.svg';
import { ReactComponent as Project } from './project.svg';
import './Nav.css';

function TopNavigation() {
  return (
    <nav>
      <ul>
        <li>
          <Link to="/dashboard">
            <Dashboard />
            <span>Dashboard</span>
          </Link>
        </li>
        <li>
          <Link to="/">
            <Project />
            <span>My Projects</span>
          </Link>
        </li>
        <li>
          <Link to="/">
            <Contributions />
            <span>Contributions</span>
          </Link>
        </li>
      </ul>
    </nav>
  )
}

export default TopNavigation;