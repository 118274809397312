import React from 'react';
import Layout from '../components/reusable/Layout';

function Dashboard() {
  return (
    <Layout>
      <div>sdf</div>
    </Layout>
  )
}

export default Dashboard;